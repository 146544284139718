import 'js/src/libs/set-webpack-nonce';
import 'core-js/stable';
import 'js/src/libs/page-initialization';
import 'bootstrap';
import 'regenerator-runtime/runtime';

import 'css/src/global';

import React from 'react';

import PublicPagesTrim from 'js/src/components/common/public-pages/PublicPagesTrim';
import HomePage from 'js/src/components/public-pages/HomePage';
import { ErrorReporter, Project } from 'js/src/libs/error-reporter';
import { renderComponent } from 'js/src/libs/redux-utils';

ErrorReporter.init(Project.FRONTEND);

const HomePageContainer = React.memo(function HomePageContainer() {
    return (
        <PublicPagesTrim >
            <HomePage/>
        </PublicPagesTrim>
    );
}
);

renderComponent(HomePageContainer);
