import 'css/src/components/common/LogoImages';

import React from 'react';

type LogoImagesProps = {
    logos: string[];
};

const LogoImages = React.memo(function LogoImages({ logos }: LogoImagesProps) {
    return (
        <div className="container py-l5-48">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-12">
                    <div className="d-flex justify-content-center pb-l5-16 text-center">
                        <h5 className="text-center">
                            Over 10 million videos created across every industry
                        </h5>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="d-flex container-fluid logos-container">
                        {
                            logos.map((logo, index) => {
                                return (
                                    <img className={`business-logos mx-l5-16 ${index >= 3 ? 'd-none d-md-block' : ''}`} src={logo} key={index} />
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
});

export default LogoImages;
