import React from 'react';

const ContentToVideoSection = React.memo(function ContentToVideoSection() {
    return (
        <div className="p-l5-16 p-l5-sm-48 content-to-video-section">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-6 col-sm-12 order-md-2 order-sm-1">
                        <div className="d-flex justify-content-center">
                            <div className="d-flex flex-column align-items-start my-l5-48">

                                <div className="d-flex flex-column my-l5-16">
                                    <h3>
                                        Easier than building a&nbsp;slide&nbsp;deck
                                    </h3>
                                </div>
                                <h5 className="my-l5-16 px-l5-0 w-75">
                                    Yes really, video can be that easy. From templated designs, preset text and image placements, styles and colour themes, our AI video tool does all the heavy-lifting for you. The work you need to do? Drag and drop. Point and click. And boom, video done.
                                </h5>

                                <div className="font-l5 font-l5--regular my-l5-16 px-l5-0 w-75"><a href="/auth/signup/" className="text-primary">Get started for free →</a></div>

                                <div className="my-l5-16 w-75">
                                    <hr />
                                </div>

                                <div className="font-l5 font-l5--regular my-l5-16 px-l5-0 w-75">
                                    “One of our colleagues called Lumen5 the PowerPoint of video making. Everybody can use PowerPoint; it’s easy.”
                                </div>
                                <div><strong>Michelle Hsiao</strong><br/><span>Visual Content Director</span></div>
                                <div>
                                    <img loading="lazy" className="company-logo" src="https://storage.googleapis.com/lumen5-site-images/website-assets/logo-siemens-color1.png" />
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* section 3.2 */}
                    <div className="col-md-6 col-sm-12 order-md-1 order-sm-2">
                        <div className="d-flex container justify-content-sm-start justify-content-md-center align-items-center">
                            <video className="w-100 banner-img" autoPlay={true} muted loop playsInline disablePictureInPicture disableRemotePlayback>
                                <source src="https://storage.googleapis.com/lumen5-site-images/slidedeck_v4.webm" type="video/webm" />
                                <source src="https://storage.googleapis.com/lumen5-site-images/slidedeck_v4.mp4" type="video/mp4" />
                            </video>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ContentToVideoSection;
