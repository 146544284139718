import 'css/src/components/common/JumboCta';

import React from 'react';

import { Urls } from 'js/src/client';


type Props = {
    header: string,
    body?: string,
    linkUrl?: string,
    linkText?: string,
    linkClass?: string,
};

const JumboCta = React.memo(function JumboCta({
    header,
    body,
    linkUrl = Urls.SIGNUP,
    linkText = 'Sign up free',
    linkClass = '',
}: Props) {
    return (
        <div className="jumbo-cta">
            <div className="container">
                <div className="row text-center">
                    <div className="col-12">
                        <div className="colorful-box p-l5-48">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <h3 className="text-white my-l5-16">{header}</h3>
                                    {body && (
                                        <h5 className="text-white my-l5-16">{body}</h5>
                                    )}
                                    <a href={linkUrl} className={`btn btn-white color-primary rounded-pill btn-lg my-l5-16 ${linkClass}`}>
                                        {linkText}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default JumboCta;
