import React from 'react';

const MediaIntegrationSection = React.memo(function MediaIntegrationSection() {
    return (
        <div className="p-l5-16 p-l5-sm-48 media-integration-section">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    {/* section 4.1 */}
                    <div className="col-md-6 offset-md col-sm-6 col-sm-12">
                        <div className="d-flex justify-content-center">
                            <div className="d-flex flex-column align-items-start my-l5-48">
                                <div className="d-flex flex-column my-l5-16">
                                    <h3>Your go-to AI video tool for  content creation</h3>
                                </div>
                                <h5 className="my-l5-16 px-l5-0 w-75">
                                    Our AI can turn any content - ideas, blogs, existing videos - into captivating videos that engage and convert.
                                </h5>
                                <div className="font-l5 font-l5--regular my-l5-16 px-l5-0 w-75"><a className="white-cta" href="/auth/signup/">Start creating →</a></div>
                                <div className="my-l5-16 w-75">
                                    <hr className="white-bar"/>
                                </div>
                                <div className="font-l5 font-l5--regular my-l5-16 px-l5-0 w-75">
                                    “The AI aspect of Lumen5 was a big draw for us, allowing the platform to be truly plug-and-play with images and media, especially with branded materials and templates.”
                                </div>
                                <div><strong>Robert Schaub</strong><br/><span>Marketing Program Manager</span></div>
                                <div>
                                    <br/>
                                    <img loading="lazy" className="company-logo" src="https://storage.googleapis.com/lumen5-site-images/bw-logo2.webp" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* section 4.2 */}
                    <div className="col-md-6 col-sm-12">
                        <div className="d-flex justify-content-sm-start justify-content-md-center align-items-center">
                            <video className="w-100 banner-img" autoPlay={true} muted loop playsInline disableRemotePlayback disablePictureInPicture>
                                <source src="https://storage.googleapis.com/lumen5-site-images/website-assets/ai-repurpose-3.mov" type="video/quicktime" />
                                <source src="https://storage.googleapis.com/lumen5-site-images/website-assets/ai-repurpose-3.webm" type="video/webm" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});


export default MediaIntegrationSection;
