import 'css/src/components/public-pages/HomePage';

import React, { useState } from 'react';
import classNames from 'classnames';

import { Urls } from 'js/src/client';
import JumboCta from 'js/src/components/common/JumboCta';
import LogoImages from 'js/src/components/common/LogoImages';
import { BUTTON_SIZES, PrimaryButton } from 'js/src/components/common/ui/Button';
import Modal, { MODAL_SIZES } from 'js/src/components/common/ui/modal';
import CompanyInfoSection from 'js/src/components/public-pages/common-sections/CompanyInfoSection';
import ContentToVideoSection from 'js/src/components/public-pages/common-sections/ContentToVideoSection';
import MediaIntegrationSection from 'js/src/components/public-pages/common-sections/MediaIntegrationSection';
import VideoPlayer from 'js/src/components/public-pages/common-sections/VideoPlayer';
import VideoPlayerWithButton from 'js/src/components/public-pages/common-sections/VideoPlayerWithButton';
import type { HomepageVideoDetails } from 'js/src/components/public-pages/homepage-data';
import {
    getDefaultTagId,
    getTagIds,
    getTagNameById,
    getVideosByTagId,
    logoImages
} from 'js/src/components/public-pages/homepage-data';


type HompageTagsProps = {
    tags: string[],
    activeTagId: string,
    changeTagId: (id: string) => void,
}
const HomepageTags = React.memo(function HomepageTags({
    tags,
    activeTagId,
    changeTagId,
}: HompageTagsProps) {
    const handleChangeTag = (id: any) => changeTagId(id);

    return (
        <div className="filter-and-search d-flex justify-content-around">
            {
                tags.map((id, idx) =>
                    <div className="search-options" key={`${id}-${idx}`}>
                        <a
                            className={classNames('px-l5-16 py-l5-8', {
                                'active': activeTagId === id,
                            })}
                            onClick={() => handleChangeTag(id)}>
                            {getTagNameById(id)}
                        </a>
                    </div>
                )
            }
        </div>
    );
});


const HomePage = React.memo(function HomePage() {
    const [showDemoVideo, setShowDemoVideo] = useState(false);
    const [demoVideoDetails, setDemoVideoDetails] = useState<HomepageVideoDetails | null>(null);
    const [currentFilterId, setCurrentFilterId] = useState(getDefaultTagId());
    const tagIds = getTagIds();

    const playDemoVideoInModal = (demoVideoDetails: HomepageVideoDetails) => {
        setDemoVideoDetails(demoVideoDetails);
        setShowDemoVideo(true);
    };

    return (
        <div className="home-page-container mt-l5-0 overflow-x-hidden">
            {/* Section 1 Grow your brand */}
            <div className="p-l5-16 p-l5-sm-48 mb-l5-48 section-1 section-grey text-center pt-l5-0">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        {/* section 1.1 */}
                        <div className="col-12 col-md-10 col-lg-8">
                            <div className="d-flex flex-column my-l5-48 pt-l5-48">
                                <h2>
                                    Grow your brand with video creation made easy with AI
                                </h2>
                                <h5 className="mt-l5-16 pt-l5-16 px-l5-0">
                                    Our online AI video creator revolutionizes the way video&nbsp;content&nbsp;is&nbsp;ideated, created, and shared.
                                </h5>
                                <h5 className="mt-l5-48">
                                    <PrimaryButton
                                        size={BUTTON_SIZES.LG}
                                        text="Sign up free"
                                        href={Urls.SIGNUP}
                                    />
                                </h5>
                            </div>
                        </div>
                        <div className="col-12">
                            <video className="w-100" autoPlay={true} muted loop playsInline disablePictureInPicture disableRemotePlayback>
                                <source src="https://storage.googleapis.com/lumen5-site-images/website-assets/public-home.webm" type="video/webm" />
                                <source src="https://storage.googleapis.com/lumen5-site-images/website-assets/public-home.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section 2  Customer logos... */}
            <LogoImages logos={logoImages}/>


            {/* Section 3  Just like presentations.... */}
            <ContentToVideoSection />


            {/* Section 4  AI assisted UX... */}
            <MediaIntegrationSection />

            {/* Section 5 Customizable templates */}
            <div className="p-l5-16 p-l5-sm-48 section-5">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        {/* section 5.1 */}

                        <div className="col-md-6 col-sm-12 order-md-2 order-sm-1">
                            <div className="d-flex justify-content-center">
                                <div className="d-flex flex-column align-items-start my-l5-48">
                                    <div className="d-flex flex-column my-l5-16">
                                        <h3>Branded templates designed&nbsp;to help your team stay on brand, on budget, and on time</h3>
                                    </div>
                                    <h5 className="my-l5-16 px-l5-0 w-75">
                                        Your brand, our Creative Services team. Bring your brand to life with a bespoke template ensuring all brand requirements are met, no matter the creator or asset.
                                    </h5>
                                    <div className="font-l5 font-l5--regular my-l5-16 px-l5-0 w-75">
                                        <a href="https://content.lumen5.com/request-demo" className="text-primary">Request a Demo →</a>
                                    </div>
                                    <div className="my-l5-16 w-75">
                                        <hr />
                                    </div>
                                    <div className="font-l5 font-l5--regular my-l5-16 px-l5-0 w-75 fst-italic">
                                        &quot;Lumen5 provides a great library of images, video footage, and music to deliver the energy and traction you need for your social posts.&quot;
                                    </div>
                                    <div>
                                        <strong>Shirley Pang</strong>
                                        <br />
                                        <span>Global Social Media Manager</span>
                                    </div>
                                    <div>
                                        <img loading="lazy" className="company-logo" src="https://storage.googleapis.com/lumen5-site-images/website-assets/logo-cisco-color.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* section 5.2 */}
                        <div className="col-md-6 col-sm-12 order-md-1 order-sm-2">
                            <div className="d-flex justify-content-sm-start justify-content-md-center align-items-center">
                                <video className="w-100 banner-img" autoPlay={true} muted loop playsInline disablePictureInPicture disableRemotePlayback>
                                    <source src="https://storage.googleapis.com/lumen5-site-images/website-assets/choose-template.webm" type="video/webm" />
                                    <source src="https://storage.googleapis.com/lumen5-site-images/website-assets/choose-template.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section 6: Template gallery  */}
            <div className="p-l5-16 p-l5-sm-48 section-6 section-blue mb-l5-48">
                <video className="w-100" autoPlay={true} muted loop playsInline disablePictureInPicture disableRemotePlayback>
                    <source src="https://storage.googleapis.com/lumen5-site-images/website-assets/public-templates.webm" type="video/webm" />
                    <source src="https://storage.googleapis.com/lumen5-site-images/website-assets/public-templates.mp4" type="video/mp4" />
                </video>
            </div>

            {/* Section 7 Stock media library */}
            <div className="p-l5-16 p-l5-sm-48 section-7">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <video className="w-100" autoPlay={true} muted loop playsInline disablePictureInPicture disableRemotePlayback>
                                <source src="https://storage.googleapis.com/lumen5-site-images/website-assets/media-search.webm" type="video/webm" />
                                <source src="https://storage.googleapis.com/lumen5-site-images/website-assets/media-search.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>

            </div>

            {/* Section 8 Lumen5 use cases */}
            <div className="p-l5-16 p-l5-sm-48 mb-l5-48 section-7">
                <div className="container">
                    {/* section 8.1 */}
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-12">
                            <div className="d-flex my-l5-16 justify-content-center text-center">
                                <h3>Explore videos made with Lumen5</h3>
                            </div>
                        </div>
                    </div>
                    {/* section 8.2 */}
                    <HomepageTags tags={tagIds} activeTagId={currentFilterId} changeTagId={setCurrentFilterId}/>
                    <div className="row">
                        {
                            getVideosByTagId(currentFilterId).map((video) => (
                                <VideoPlayerWithButton
                                    key={`tagged-videos-${video.src}`}
                                    className="col-md-4"
                                    imgSrc={video.poster}
                                    videoSrc={video.src}
                                    onClick={() => playDemoVideoInModal(video)}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>

            {/* Section 9 CTA */}
            <div className="mb-l5-48">
                <JumboCta
                    header="Join over 2,500,000 companies using Lumen5 to tell their stories."
                    body="Lumen5 helps marketers, publishers, and brands create video content with AI, without the hassle or technical expertise."
                />
            </div>

            {/* Section 10 Case studies */}
            <div className="p-l5-16 p-l5-sm-48 section-7">
                <div className="container">
                    {/* section 10.1 */}
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8">
                            <div className="d-flex flex-column my-l5-16 align-items-center text-center">
                                <h3 className="text-center">Need more convincing?</h3>
                            </div>
                            <h5 className="my-l5-16 px-l5-0 text-center">
                                Discover how leading brands are getting results with Lumen5.
                            </h5>
                        </div>
                    </div>
                    {/* section 10.2 */}
                    <CompanyInfoSection />
                </div>
            </div>


            {/* Section 11 Learning Resources */}
            <div className="p-l5-16 p-l5-sm-48 section-9">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        {/* section 11.1 */}
                        <div className="col-md-6 col-sm-12 order-md-1 order-sm-2">
                            <div className="d-flex justify-content-center">
                                <div className="d-flex flex-column align-items-start my-l5-48">
                                    <div className="d-flex flex-column my-l5-16">
                                        <h3>The AI tool for video marketing</h3>
                                    </div>
                                    <h5 className="my-l5-16 px-l5-0 w-75">
                                        Our mission is to help you become your brand&apos;s best storyteller.
                                    </h5>
                                    <div className="font-l5 font-l5--regular my-l5-16 px-l5-0 w-75">
                                        <a href="/learn/" className="text-primary">Start learning for free →</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* section 11.2 */}
                        <div className="col-md-6 col-sm-12 order-md-2 order-sm-1">
                            <div className="d-flex justify-content-sm-start justify-content-md-center align-items-center">
                                <video className="w-100 banner-img" autoPlay={true} muted loop playsInline disablePictureInPicture disableRemotePlayback>
                                    <source src="https://storage.googleapis.com/lumen5-site-images/website-assets/learning-resources.webm" type="video/webm" />
                                    <source src="https://storage.googleapis.com/lumen5-site-images/website-assets/learning-resources.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Video Player Modal */}
            <Modal
                showing={showDemoVideo}
                onHidden={() => setShowDemoVideo(false)}
                closeable={false}
                size={demoVideoDetails ? demoVideoDetails.modalSize : MODAL_SIZES.XL}
                dialogClassName={'transparent-dialog text-center'}
                backdropClassName={'darker-backdrop'}
            >
                <div className="container-fluid">
                    {demoVideoDetails &&
                        <VideoPlayer
                            posterUrl={demoVideoDetails.poster}
                            videoUrl={demoVideoDetails.src}
                            showControls={true}
                            loop={false}
                            muted={false}
                            autoPlay={true}
                            classNames={`m-l5-4 rounded-video focus-outline-none 
                                ${demoVideoDetails.aspectRatio === 'vertical' ? 'w-100' : 'w-100'}`
                            }
                        />
                    }
                </div>
            </Modal>
        </div>
    );
});

export default HomePage;
